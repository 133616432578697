let dots = document.querySelectorAll('.map__link');
let wraps = document.querySelectorAll('.dot-wrapper');
if (window.innerWidth > 1200)  {
	dots.forEach(function(dot) {
		dot.addEventListener('click',function() {
			wraps.forEach(function (wrap){
				killMapPopup(wrap);
			});
			mapPopup(this);
		});

		document.body.addEventListener('click',(e)=>{
			if(!e.target.classList.contains('dot-wrapper') && !e.target.classList.contains('map__link')) {
				wraps.forEach(function (wrap){
					killMapPopup(wrap);
				});
			}
		});

		document.body.addEventListener('touchstart',(e)=>{
			if(!e.target.classList.contains('dot-wrapper') && !e.target.classList.contains('map__link')) {
				wraps.forEach(function (wrap){
					killMapPopup(wrap);
				});
			}
		});
	});
}


function mapPopup(dot) {
	let link = dot,
		block = link.nextElementSibling;
	block.classList.add('clicked');

}

function killMapPopup (wrap) {
	wrap.classList.remove('clicked');
}