class Slider {
	constructor(slider) {
		this.slider = slider;
		this.sliderWrapper = this.slider.querySelector('.slider__wrapper');
		this.sliderPrev = document.querySelector('.control-prev');
		this.sliderNext = document.querySelector('.control-next');


		this.InitSlider();

	}

	InitSlider() {
		let self = this;
		this.slider = new Swiper (this.slider,{
			speed: 600,
			wrapperClass: 'slider__wrapper',
			slideClass: 'slider__slide',
			slideActveClass: 'slider__slide--active',
			grabCursor: true,
			loop: true,
			spaceBetween: 50,
			prevButton: this.sliderPrev,
			nextButton: this.sliderNext
		});
	}
}

let sliders = document.querySelectorAll('.slider');
sliders.forEach(function(slider){
	new Slider(slider);
});