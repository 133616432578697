
class Form{
	constructor(form) {
		this.form = form;
		this.button = this.form.querySelector('button[type="submit"]');
		this.fields = this.form.querySelectorAll('input,textarea');
		this.url = form.dataset.url;

		this.events();
	}
	events() {
		let self = this;
		this.form.addEventListener('submit', function(e) {
			e.preventDefault();
			self.sendForm();
        });
	}

	serialize(form) {
		let field, l, s = [];
		if (typeof form === 'object' && form.nodeName === "FORM") {
			let len = form.elements.length;
			for (let i=0; i<len; i++) {
				field = form.elements[i];
				if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
					if (field.type === 'select-multiple') {
						l = form.elements[i].options.length;
						for (let j=0; j<l; j++) {
							if(field.options[j].selected)
								s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value);
						}
					} else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
						s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value);
					}
				}
			}
		}
		return s.join('&').replace(/%20/g, '+');
	}

	sendForm() {
		let self = this,
			confident = this.form.querySelector('.form__confidential'),
			button = this.form.querySelector('.form__button'),
			$modalW = this.form.closest('.popup'),
			tel = this.form.querySelector('input[type="tel"]').value,
			params = this.serialize(this.form);

		if(self.fieldValid()){
			let request = new XMLHttpRequest();
			request.open('POST', this.url, true);
			request.onload = () => {
				if (request.status >= 200 && request.status < 400) {

					self.fields.forEach(field => {
						field.disabled = true;
						// field.value = '';
					});
					// Закрываем попап если таковой есть
					if(!!$modalW && $modalW.classList.contains('opened')){
						$modalW.classList.remove('opened');
					}
					// Открываем прощание
					let sendMessage = document.querySelector('.popup[data-popup="thankyou"]');
					let final = new PopupThanks(sendMessage);
					final.phone.textContent = tel;
					final.openPopup();
					self.fields.forEach(field => {
						field.disabled = false;
						field.value = '';
					});
				}

			};
			request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
			request.send(params);
		}
	}

	clearField() {
		let button = this.form.querySelector('.form__button'),
			confident = this.form.querySelector('.form__confidential');
		this.fields.forEach(field => {
		 	field.value = '';
		 	field.disabled = false;
		 	button.style.display = 'block';
		 	confident.style.display = 'block';

			this.clearError(field);
		});
	}

	clearError(field) {
		field.classList.remove('error');
	}

	fieldValid() {
		let isValidForm = true;
		const fields = this.form.querySelectorAll('input[required], textarea[required]');
		fields.forEach(field => {
			this.clearError(field);
			let	patternEmail = /^[a-z0-9-._]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i,
				type = field.getAttribute('type'),
				isValidField = true,
				isFilled = field.value.length > 0,
				isEmail = !((type === 'email') && (!patternEmail.test(field.value)));

			if(!isFilled) isValidField = false;
			if(!isEmail) isValidField = false;

			if(!isValidField) {
				isValidForm = false;
				field.classList.add('error');
			}
		});
		return isValidForm;
	}
}



let forms = document.querySelectorAll('form');
forms.forEach(function(form){
	new Form(form);
});
		jQuery( function($){
	$(".mask-phone").mask("+7 999 999-99-99");
});