'use strict';

class Calculator {
	constructor(calculator) {
		this.calculator = calculator;
		this.sumInput = this.calculator.querySelector('.calculator__sum .calculator__input');
		this.sumRange = this.calculator.querySelector('.calculator__sum .calculator__range');
		this.delayInput = this.calculator.querySelector('.calculator__delay .calculator__input');
		this.delayRange = this.calculator.querySelector('.calculator__delay .calculator__range');
		this.result = this.calculator.querySelector('.calculator__count');


		this.sumForm = document.querySelector('input#sum');
		this.daysForm = document.querySelector('input#days');
		this.resultForm = document.querySelector('input#result');

	}
}

class FactoringCalc extends Calculator {
	constructor(calculator) {
		super(calculator);

		this.InitSliders();
		this.events();
		this.getResult();
	}

	InitSliders() {
		let self = this,
			step_sum = parseFloat(this.sumRange.getAttribute('data-step')),
			min_sum = parseFloat(this.sumRange.getAttribute('data-min')),
			max_sum = parseFloat(this.sumRange.getAttribute('data-max')),

			step_delay = parseFloat(this.delayRange.getAttribute('data-step')),
			min_delay = parseFloat(this.delayRange.getAttribute('data-min')),
			max_delay = parseFloat(this.delayRange.getAttribute('data-max'));

		noUiSlider.create(this.sumRange, {
			start: [step_sum],
			step: step_sum,
			behaviour: 'tap-drag',
			connect: [true, false],
			range: {
				'min': min_sum,
				'max': max_sum
			},
			format: wNumb({
				decimals: 0
			})
		});


		noUiSlider.create(this.delayRange, {
			start: [min_delay],
			step: step_delay,
			behaviour: 'tap-drag',
			connect: [true, false],
			range: {
				'min': min_delay,
				'max': max_delay
			},
			format: wNumb({
				decimals: 0
			})
		});
	}
	events () {

		let self = this,
			time = 1488;
		this.sumRange.noUiSlider.on('update', function(values , handle) {
			let Round = wNumb({
				decimals: 0,
				thousand: ' '
			});

			self.sumInput.value = values[handle];
			self.sumInput.value = Round.to(parseFloat(self.sumInput.value));
			self.getResult();
		});

		this.sumInput.addEventListener('change', function() {
			self.sumRange.noUiSlider.set(this.value);
			self.getResult();
		});


		var timerId;
		this.sumInput.addEventListener('input', function() {
			let val = this.value;
			clearTimeout(timerId);
			timerId = setTimeout(function(){
				self.sumRange.noUiSlider.set(val);
				self.getResult();
			}, time);
		});


		this.delayRange.noUiSlider.on('update', function(values , handle) {
			self.delayInput.value = values[handle];
			self.getNumEnding(self.delayInput.value,'день','дня','дней');
			self.getResult();
		});

		this.delayInput.addEventListener('change', function() {
			self.delayRange.noUiSlider.set(this.value);
			self.getNumEnding(self.delayInput.value,'день','дня','дней');
			self.getResult();
		});

		var timerId;
		this.delayInput.addEventListener('input', function() {
			let val = this.value;
			clearTimeout(timerId);
			timerId = setTimeout(function(){
				self.delayRange.noUiSlider.set(val);
				self.getNumEnding(self.delayInput.value,'день','дня','дней');
				self.getResult();
			}, time);
		});

	}


	getNumEnding(n, form1, form2, form3) {
		let text = this.calculator.querySelector('.calculator__days'),
			n1 = 0;
		n = Math.abs(n) % 100;
		n1 = n % 10;
		if (n > 10 && n < 90) text.innerHTML = form3;
		if (n1 > 1 && n1 < 5) text.innerHTML = form2;
		if ((n1 > 0 && n1 <= 9) && (n >= 5 && n <= 14)) text.innerHTML = form3;
		if (n1 == 1) text.innerHTML = form1;
		if (n == 11 && n1 == 1) text.innerHTML = form3;
	}

 	getResult() {
 		let self = this,
			Format = wNumb({
				decimals: 0,
				thousand: ' ',
				suffix: ' ₽'
			}),
			result = 0,
			firstPayment = 0,
			commisionPayment = 0,
			firstMonth = 0,
			secondMonth = 0,
			thirdMonth = 0;

		firstPayment = this.getFirstPayment();
		commisionPayment = this.getDailyCommissions();

		result = firstPayment + commisionPayment;

		this.resultForm.value = Format.to(result);
		this.result.innerHTML = this.resultForm.value;
	}

	getFirstPayment() {

		let Round = wNumb({
			decimals: 0,
			thousand: ' '
		});
		let sumValue = Round.from(this.sumInput.value);
		this.sumForm.value =  Round.to(sumValue);
		for (let i = 0; i < intervals.length; i++) {
			if (sumValue > intervals[i].from && sumValue <= intervals[i].to) {
				return (sumValue * intervals[i].commission / 100);

			}
		}
	}

	getDailyCommissions() {
		let Round = wNumb({
			decimals: 0,
			thousand: ' '
		});

		let sumValue = Round.from(this.sumInput.value),
			delayValue = parseFloat(this.delayInput.value),
			payment = 0,
			i = 0,
			rate = 0;

		this.daysForm.value = delayValue;

		let main = Math.floor(delayValue / 30),
			extras = delayValue % 30,
			count_days = delayValue - (30 * main);

		for (i = 0; i < main; i++ ) {
			payment +=  sumValue * days[i].rate * (30 * 0.9) / 100;
		}
		if (extras === 0) {
			return payment;
		}
		else {
			for (i = 0; i < days.length; i++) {
				if (delayValue >= days[i].from && delayValue < days[i].to) {
					rate = days[i].rate;
				}
			}

			payment += sumValue * rate * (count_days * 0.9) / 100;
		}
		return payment;
	}
}

class BorrowCalc extends Calculator {
	constructor(calculator) {
		super(calculator);
		this.percent = this.calculator.getAttribute('data-percent');
		this.borrowType = this.calculator.getAttribute('data-borrow-type');

		this.InitSliders();
		this.events();
		this.getResult();
	}

	InitSliders() {
		let self = this,
			step_sum = parseFloat(this.sumRange.getAttribute('data-step')),
			min_sum = parseFloat(this.sumRange.getAttribute('data-min')),
			max_sum = parseFloat(this.sumRange.getAttribute('data-max')),

			step_delay = parseFloat(this.delayRange.getAttribute('data-step')),
			min_delay = parseFloat(this.delayRange.getAttribute('data-min')),
			max_delay = parseFloat(this.delayRange.getAttribute('data-max'));

		noUiSlider.create(this.sumRange, {
			start: [step_sum],
			step: step_sum,
			behaviour: 'tap-drag',
			connect: [true, false],
			range: {
				'min': min_sum,
				'max': max_sum
			},
			format: wNumb({
				decimals: 0
			})
		});


		noUiSlider.create(this.delayRange, {
			start: [min_delay,],
			step: step_delay,
			behaviour: 'tap-drag',
			connect: [true, false],
			range: {
				'min': min_delay,
				'max': max_delay
			},
			format: wNumb({
				decimals: 0
			})
		});
	}
	events () {
		let self = this,
			time = 1488;
		this.sumRange.noUiSlider.on('update', function(values , handle) {
			let Round = wNumb({
				decimals: 0,
				thousand: ' '
			});

			self.sumInput.value = values[handle];
			self.sumInput.value = Round.to(parseFloat(self.sumInput.value));
			self.getResult();
		});

		this.sumInput.addEventListener('change', function() {
			self.sumRange.noUiSlider.set(this.value);
			self.getResult();
		});

		var timerId;
		this.sumInput.addEventListener('input', function() {
			let val = this.value;
			clearTimeout(timerId);
			timerId = setTimeout(function(){
				self.sumRange.noUiSlider.set(val);
				self.getResult();
			}, time);
		});

		this.delayRange.noUiSlider.on('update', function(values , handle) {
			self.delayInput.value = values[handle];
			self.getNumEnding(self.delayInput.value,'день','дня','дней');
			self.getResult();
		});

		this.delayInput.addEventListener('change', function() {
			self.delayRange.noUiSlider.set(this.value);
			self.getNumEnding(self.delayInput.value,'день','дня','дней');
			self.getResult();
		});

		var timerId;
		this.delayInput.addEventListener('input', function() {
			let val = this.value;
			clearTimeout(timerId);
			timerId = setTimeout(function(){
				self.delayRange.noUiSlider.set(val);
				self.getNumEnding(self.delayInput.value,'день','дня','дней');
				self.getResult();
			}, time);
		});
	}
	getNumEnding(n, form1, form2, form3) {
		if (this.borrowType !== 'tender') {
			return false;
		}
		else  {
			let text = this.calculator.querySelector('.calculator__days'),
				n1 = 0;
			n = Math.abs(n) % 100;
			n1 = n % 10;
			if (n > 10 && n < 90) text.innerHTML = form3;
			if (n1 > 1 && n1 < 5) text.innerHTML = form2;
			if ((n1 > 0 && n1 <= 9) && (n >= 5 && n <= 14)) text.innerHTML = form3;
			if (n1 == 1) text.innerHTML = form1;
			if (n == 11 && n1 == 1) text.innerHTML = form3;
		}
	}

 	getResult() {
 		let Round = wNumb({
			decimals: 0,
			thousand: ' '
		});

 		let self = this,
			Format = wNumb({
				decimals: 0,
				thousand: ' ',
				suffix: ' ₽'
			});

		let sumValue = Round.from(this.sumInput.value),
			delayValue = parseFloat(this.delayInput.value),
			percent = eval(this.percent),
			result = 0;

		this.sumForm.value = Round.to(sumValue);
		this.daysForm.value = delayValue;

		result = sumValue * delayValue * percent / 100;

		this.resultForm.value = Format.to(result);
		this.result.innerHTML = this.resultForm.value;
	}
}

let calcs = document.querySelectorAll('.calculator--factoring');
calcs.forEach(function(calc){
	new FactoringCalc(calc);
});

let calcus = document.querySelectorAll('.calculator--borrowing');
calcus.forEach(function(calcul){
	new BorrowCalc(calcul);
});