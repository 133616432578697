
class InvestCalculator {
	constructor(calculator) {
		this.calculator = calculator;
		this.sumInput = this.calculator.querySelector('.calculator__sum .calculator__input');
		this.sumRange = this.calculator.querySelector('.calculator__sum .calculator__range');
		this.buttons = this.calculator.querySelectorAll('.calculator__radio');
		this.radioButton = this.calculator.querySelector('input[checked]');
		this.result = this.calculator.querySelector('.calculator__count');
		this.monthChecked = this.calculator.querySelector('input#reward-month');
		this.data;

		this.sumForm = document.querySelector('input#sum');
		this.daysForm = document.querySelector('input#days');
		this.monthForm = document.querySelector('input#reward');
		this.resultForm = document.querySelector('input#result');


		this.InitSlider();
		this.events();
		this.getResult();
	}

	InitSlider() {
		let self = this,
			step_sum = parseFloat(this.sumRange.getAttribute('data-step')),
			min_sum = parseFloat(this.sumRange.getAttribute('data-min')),
			max_sum = parseFloat(this.sumRange.getAttribute('data-max'));

		noUiSlider.create(this.sumRange, {
			start: [step_sum],
			step: step_sum,
			behaviour: 'tap-drag',
			connect: [true, false],
			range: {
				'min': min_sum,
				'max': max_sum
			},
			format: wNumb({
				decimals: 0
			})
		});
	}

	events() {
		let self = this,
			time = 1488;
		this.sumRange.noUiSlider.on('update', function(values , handle) {
			let Round = wNumb({
				decimals: 0,
				thousand: ' '
			});

			self.sumInput.value = values[handle];
			self.sumInput.value = Round.to(parseFloat(self.sumInput.value));
			self.getResult();
		});

		this.sumInput.addEventListener('change', function() {
			self.sumRange.noUiSlider.set(this.value);
			self.getResult();
		});

		var timerId;
		this.sumInput.addEventListener('input', function() {
			let val = this.value;
			clearTimeout(timerId);
			timerId = setTimeout(function(){
				self.sumRange.noUiSlider.set(val);
				self.getResult();
			}, time);
		});

		this.buttons.forEach(function(button) {
			button.addEventListener('change', function() {
				self.radioButton = this;
				self.getResult();
			});
		});
		this.monthChecked.addEventListener('change', function() {
			self.getResult();
		});
	}

	defineData() {
		this.data = {
			'amount': JSON.parse(this.calculator.dataset.amount),
			'days': JSON.parse(this.calculator.dataset.days)
		}
	}

	defineSum() {
		this.defineData();
		let Round = wNumb({
			decimals: 0,
			thousand: ' '
		}),
			sumValue = Round.from(this.sumInput.value),
			conditions = this.data.amount;
		this.sumForm.value = Round.to(sumValue);
		for (let i = 0; i < conditions.length; i++) {
			let key = conditions[i];
			if (sumValue >= key.from && sumValue <= key.to) {
				return (i);

			}
		}
	}

	defineDays() {
		let i = this.defineSum(),
			checkedVal = this.radioButton.value,
			percentRanged = this.data.days[i],
			percentNeeded = percentRanged[checkedVal];

		this.daysForm.value = checkedVal;

		return percentNeeded;
	}

	getResult() {
		let self = this,
		Format = wNumb({
			mark: ',',
			decimals: 1,
			thousand: ' ',
			suffix: ' %'
		});

		let result = this.defineDays();
		if (this.monthChecked.checked === true)  {
			result = result + 0.5;
			this.monthForm.value = 'в конце срока инвестирования';
		}
		else {
			this.monthForm.value = 'ежемесячно';
		}
		this.resultForm.value = Format.to(result);
		this.result.innerHTML = this.resultForm.value;
	}



}

let calculators = document.querySelectorAll('.calculator--invest');
calculators.forEach(function(calculator){
	new InvestCalculator(calculator);
});