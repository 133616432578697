(function() {
	class File {
		constructor(file) {
			this.file = file;
			this.drag = file.querySelector('.file__drag');
			this.input = file.querySelector('.file__input');
			this.label = file.querySelector('.file__label');
			this.output = file.querySelector('.file__output');
			this.list = file.querySelector('.file__list');

			this.events();
		}

		events() {
			this.input.addEventListener('change', e => {
				this.getDropFiles(e);
			});

			this.drag.addEventListener('dragover', e => {
				e.preventDefault();
				e.stopPropagation();
				this.drag.classList.add('file__drag--over');
			});

			this.drag.addEventListener('dragleave', e => {
				e.preventDefault();
				e.stopPropagation();
				this.drag.classList.remove('file__drag--over');
			});

			this.drag.addEventListener('drop', e => {
				e.preventDefault();
				e.stopPropagation();
				this.drag.classList.remove('file__drag--over');
				this.getDropFiles(e);
			});

			this.output.addEventListener('click', e => {
				e.preventDefault();
				e.stopPropagation();
			})
		}

		handleAdd(files) {
			for(let i=0; i < files.length; i++) {
				if(files[i] != '') {

					let item = document.createElement('li');
					item.className = 'file__item file__item--loading';
					item.textContent = files[i].name;
					this.output.appendChild(item);

					if(files[i].size > 10485760) {
						this.handleErrorDownload(item);
					} else {
						this.download(files[i], item);
					}
				}
			}
		}

		handleSuccessDownload(item, link) {
			// console.log('link,', link);
			item.classList.remove('file__item--loading');
			item.classList.add('file__item--ok');

			let list = this.list.value;
				// console.log('1', list);
			list += link + ' ';
			// console.log('2', list);
			this.list.value = list;
		}

		handleErrorDownload(item) {
			item.classList.remove('file__item--loading');
			item.classList.add('file__item--error');
		}

		download(file, item) {
			let formData = new FormData();
			formData.append('file', file);

			// console.log(file);

			let request = new XMLHttpRequest();
			request.open('POST', '/wp-content/themes/tsl-theme/ajax/mail/fileupload.php', true);
			request.onload = () => {

				if (request.status == 200) {
					if(request.responseText === 'error') {
						this.handleErrorDownload(item);
					} else {
						// console.log(request.responseText);
						this.handleSuccessDownload(item, request.responseText);
					}
				} else {
					this.handleErrorDownload(item);
				}
			};
			// console.log(formData);
			request.send(formData);
		}

		getDropFiles(e) {
			let files;

			if(e.dataTransfer) {
				files = e.dataTransfer.files;
			} else if(e.target) {
				files = e.target.files;
			}

			this.handleAdd(files);
		}
	}

	let files = document.querySelectorAll('.file');
	files.forEach(file => {
		new File(file);
	});

})();