let menu = document.querySelector('.menu-mobile'),
	burger = document.querySelector('.header__burger'),
	openCls = 'menu-mobile--opened',
	menu_overlay = document.querySelector('.menu-mobile__overlay'),
	opened = false;

burger.addEventListener('click',() =>{
	document.body.style.overflow = 'hidden';
	menu.classList.add(openCls);
	opened = true;
});

menu_overlay.addEventListener('click',() =>{
	document.body.style.overflow = 'auto';
	menu.classList.remove(openCls);
	opened = false;
});

window.addEventListener('keydown', e => {
	if(e.keyCode === 27 && opened === true) {
		document.body.style.overflow = 'auto';
		menu.classList.remove(openCls);
		opened = false;
	}
});

window.addEventListener('resize', () => {
	if(window.innerWidth > 1200 && opened === true){
		document.body.style.overflow = 'auto';
		menu.classList.remove(openCls);
		opened = false;
	}
});