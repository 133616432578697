function Disclaimer(){
  this.init();
}


Disclaimer.prototype = {

  init:function(){
    var div = document.createElement('div');
    	div.id = "disclaimerWrapper";
    	div.className = "disclaimer__wrapper";

    if (!this.getCookie('disclaimer')){
      div.innerHTML = `
      	<style>
      	.disclaimer__wrapper{
      		position: fixed;
      		z-index: 9999;
      		background-color: #000;
      		bottom: 0px;
      		padding: 16px 30px;
			width: 100%;
			box-sizing: border-box;
      	}
		.disclaimer{
			display: flex;
			justify-content: center;
			color: #fff;
			align-items: center;
		}

		.disclaimer__text{
			margin-right: 50px;
			font-size: 15px;
			line-height: 20px;
			color: #e6e6e6;
			font-weight: 200;
		}

		.disclaimer__text a {
			color: #fff;
			border-bottom: 1px solid #fff;
			transition: all .3s;
			line-height: 1em;
			display: inline-block;
		}

		.disclaimer__text a:hover {
			border-bottom-color: transparent;
		}

		.disclaimer__close{
			padding: 10px 25px;
			background-color: transparent;
			color: #fff;
			cursor: pointer;
			font-size: 18px;
			border: 1px solid #fff;
			border-radius: 3px;
			transition: all .3s;
			min-width: 155px;
		}
		.disclaimer__close:hover{
			background-color: #ffffff;
			color: #434550;
		}

		@media screen and (max-width: 1250px) {
			.disclaimer__text{
				text-align: left;
				font-size: 14px;
				margin-right: 15px;
			}

			.disclaimer__wrapper{
				padding: 16px 20px;
			}
		}
		@media screen and (max-width: 1040px) {

			.disclaimer__text{
				margin-right: 0;
				margin-bottom: 10px;
			}

		}

		@media screen and (max-width: 767px) {

			.disclaimer{
				flex-direction: column;
				align-items: left;
			}

			.disclaimer__wrapper{
				background-color: #000;
			}

			.disclaimer__text{
				font-size: 13px;
				font-weight: 500;
			}
		}

      	</style>
      	<div id="disclaimer" class="disclaimer">
          <span class="disclaimer__text">Мы используем файлы cookie для анализа событий на нашем веб-сайте и наилучшего предоставления информации для пользователей. Продолжая просмотр сайта, вы принимаете его <a href="javascript://" target="_blank">условия</a>.</span>

          <button id="disclaimerClose" class="disclaimer__close">Продолжить</button>
        </div>
      `;
      document.body.appendChild(div);
      this.el = disclaimerWrapper;
      this.close = disclaimerClose;
      this.getCookieLink();
      this.bindEvents();
    }
  },
  getCookie: function(name){
    var matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
  },
  getCookieLink: function () {
  	var cookieLink = document.getElementById('unseen').innerHTML;
  	var link = this.el.querySelector('a');
  	link.setAttribute('href',cookieLink);
  },
  setCookie:function(){
    var date = new Date(new Date().getTime() + 86400 * 1000);
    document.cookie = "disclaimer=1; path=/; expires=" + date.toUTCString();
  },
  deleteCookie:function(){
    var date = new Date(0);
    document.cookie = "disclaimer=; path=/; expires=" + date.toUTCString();
  },
  bindEvents:function(){
    var that = this;

    this.close.addEventListener("click", function(){
      that.setCookie();
      that.el.remove();
    });
  }

}

document.addEventListener('DOMContentLoaded', function(){
	var ds = new Disclaimer();
});