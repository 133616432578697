'use strict';
// Андрюхе привет!
$(function() {

	// Операции при загрузке
	stickyFooter();
	// Операции при ресайзе
	$(window).on('resize', function() {
		stickyFooter();
	});


});

	// Функции
	// Прижимаем подвал к низу экрана
	function stickyFooter() {
		var $footer = $('.footer');
		$footer.siblings('.spacer').remove();
		var contentHeight = $('body').height(),
			windowHeight = $(window).height();
		if(contentHeight < windowHeight) {
			$footer.before('<div class="spacer" style="height: ' + (windowHeight - contentHeight) + 'px;"></div>');
		}
	}