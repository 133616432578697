
class Quiz{
	constructor(quiz) {
		this.quiz = quiz;
		this.buttons = this.quiz.querySelectorAll('.quiz__button');
		this.points = 0;
		this.questionOne = this.quiz.querySelector('.quiz__question--one');
		this.questionTwo = this.quiz.querySelector('.quiz__question--two');
		this.result = this.quiz.querySelector('.quiz__result');
		this.results = this.result.querySelectorAll('.result__item');
		this.reset = this.result.querySelector('.result__reset');
		this.class = 'visible';

		this.events();
	}
	events() {
		let self = this;
		this.buttons.forEach(function(button) {
			button.addEventListener('click', function() {
				let parent = this.parentNode.parentNode;
				self.countPoints(this);
				if (parent.classList.contains('quiz__question--one')) {
					self.firstStep();
				}

				if (parent.classList.contains('quiz__question--two')) {
					self.secondStep();
				}
			});
		});

		this.reset.addEventListener('click', function() {
			self.resetQuiz();
		});
	}

	countPoints(elem) {
		let target = elem,
		score = Number(target.getAttribute('data-points'));
		this.points += score;
	}

	firstStep() {
		let self = this;
		if (this.questionOne.classList.contains(this.class)) {
			this.questionOne.classList.remove(this.class);

			if (this.points !=6 && this.points != 5) {
				self.questionTwo.classList.add(this.class);
			}
			else {
				this.result.style.display = 'block';
				self.finalStep();
			}
		}
	}

	secondStep() {
		if (this.questionTwo.classList.contains(this.class)) {
			this.questionTwo.classList.remove(this.class);
			this.result.style.display = 'block';
		}
		this.finalStep();
	}

	finalStep() {
		let arrResults = [],
			self = this;

		if(this.points == 6 || this.points == 5) {
			arrResults = ['result__item--two'];
		}
		if(this.points == 8) {
			arrResults = ['result__item--one'];
		}
		if(this.points == 9) {
			arrResults = ['result__item--three','result__item--five'];
		}
		if(this.points == 10) {
			arrResults = ['result__item--four'];
		}

		this.renderResult(arrResults);
		arrResults = [];
	}

	renderResult(arr) {
		let self = this,
			items = arr;
		for (var i = 0; i < items.length; i++) {
			let item = self.result.querySelector('.' + items[i]);
			item.style.display = 'block';
		}
	}

	resetQuiz() {
		this.points = 0;
		this.result.style.display = 'none';
		this.questionOne.classList.add(this.class);
		this.results.forEach( item => {
			item.style.display = 'none';
		});
	}


}

let quizes = document.querySelectorAll('.quiz');
quizes.forEach(function(quiz){
	new Quiz(quiz);
});