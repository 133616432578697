// Скролл с первого экрана
(function() {
	const btnScroll = document.querySelector('.page-banner__icon'),
		mainBtnScroll = document.querySelector('.page-banner__button--main'),
		banner = document.querySelector('.page-banner');
	let target = 0;
	if(banner.classList.contains('page-banner--main')) {
		target = banner.offsetHeight
	}

	else {
		target = banner.offsetHeight + document.querySelector('.header').offsetHeight;
	}

	if(btnScroll){
		btnScroll.addEventListener('click', () => {
			window.scroll({
				top: target,
				left: 0,
				behavior: 'smooth'
			});
		});
	}

	if(mainBtnScroll){
		mainBtnScroll.addEventListener('click', () => {
			window.scroll({
				top: target,
				left: 0,
				behavior: 'smooth'
			});
		});
	}

})();

window.addEventListener('resize', function () {
	resizeDots();
})

function resizeDots() {
	let head_top  = document.querySelector('.header__row'),
		banner_top = document.querySelector('.page-banner__head'),
		left = document.querySelector('.page-banner__dots-left'),
		right = document.querySelector('.page-banner__dots-right');
	if (!banner_top) return false;
	else {
		let bannerHeadWidth = banner_top.clientWidth;
		let dotsWidth = ((window.innerWidth - head_top.clientWidth) / 2 ) - 20 + 'px';
		left.style.width = dotsWidth;
		let param = 'calc(100% - ' + dotsWidth + ' - ' + bannerHeadWidth + 'px)'
		right.style.width = param;

		// console.log(dotsWidth);
		// console.log(bannerHeadWidth)
	}

}

	resizeDots();